
import Api from "../../common/helpers/api";
import getApiUrl from "../../common/helpers/api/urls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    resStatus: false,
    resStatusCode: 200,
    responseData: {},
    message: ""
}

export const getSeesionInfo = createAsyncThunk(
    'sessionInfo/getSessionId',
    async (data) => {
        const { urlKeyName, formData = { applicationId: 1, interfaceId: 1 }, isIG = false } = data;
        let sessionPayload = { ...formData };
        if (isIG) {
            sessionPayload = {
                applicationId: 1,
                interfaceId: 2
            }
        }
        const url = getApiUrl.getApiUrlInfo(urlKeyName);
        return await Api.post(url, sessionPayload);
    }
)

const sessionInfoSlice = createSlice({
    name: 'sessionInfo',
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(getSeesionInfo.pending, (state, action) => {
            state.loading = true;
        }).addCase(getSeesionInfo.fulfilled, (state, action) => {
            // console.log("action.payload.data");
            // console.log(action.payload.data);
            state.loading = false;
            if (action.payload.status) {
                state.resStatus = action.payload.data.status;
                state.resStatusCode = action.payload.status;
                state.responseData = action.payload.data;
            } else {
                state.resStatusCode = action.payload.statusCode;
                state.responseData = action.payload.data;
                state.message = action.payload.message;
            }
        }).addCase(getSeesionInfo.rejected, (state, action) => {
            state.loading = false;
            state.message = `some thing went wrong!`;
        });
    }
});

export default sessionInfoSlice.reducer;

//fulfilled, success and error or rejected