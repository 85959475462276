import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import { SnackbarProvider } from 'notistack';
import AppRoute from './route/AppRoute';
import { Provider } from 'react-redux';
import store from './store/index';

function App() {
  return (
    <React.Fragment>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
          <AppRoute />
        </Provider>
      </SnackbarProvider>
    </React.Fragment>
  );
}

export default App;
