import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    originData: [],
    originSelected: null,
}
const originDataSlice = createSlice({
    name: 'originData',
    initialState: initialState,

    reducers: {
        originDataInfo: function (state, action) {
            state.originData = action.payload;
        },
        setSelectedoriginData: function (state, action) {
            state.originSelected = action.payload;
        }
    },
});
export const { originDataInfo, setSelectedoriginData } = originDataSlice.actions;
export default originDataSlice.reducer;