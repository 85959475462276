import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Button, Box, TextField, Autocomplete, Snackbar, SnackbarContent, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { originDataInfo, setSelectedoriginData } from "../store/slices/originDataSlice";
import { getSeesionInfo } from "../store/slices/sessionInfoSlice";
import UseSessionStorage from "../common/hooks/UseSessionStorage";
import { setDialogActionInfo, setInputCtrlStatus } from "../store/slices/dialogActionSlice";
import { getAiShoppingInfoSlice, clearUserCtrlData, aiChatWelcomeMsg } from "../store/slices/shoppingAssistanceSlice";
import { resetSelectedPlanDataObjForNewTrip, setMobileMenuActiveStatus } from "../store/itinerary-api-slices/itinerary-slice";
import SkeletonInputCtrl from "../common/skeletons/SkeletonInputCtrl";
import reactLogo from "./../assets/img/cc-logo.png";
import closeBtn from "../assets/img/close-btn.png";
import hamburgerMenu from "../assets/ai-img/solar_hamburger-menu-bold-duotone.svg";
import { Icon } from "@iconify/react";
import SideDrawer from "./SideDrawer";
import { getNaviagtionInfo, setHeaderMobileMenuSidebarActiveStatus } from "../store/slices/navigationSlice";
// import MenuIcon from '@mui/icons-material/Menu';
import MobileMenuSidebar from "./MobileMenuSidebar";
import "./../assets/css/header.scss";

const Header = () => {
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [locationInfo] = UseSessionStorage("location_info");
  const [tokenInfo, setTokenInfo] = UseSessionStorage('tokenInfo', "");
  const [open, setOpen] = useState({ dialogStatus: false, location: {} });
  const [selectedDrawer, setSelectedDrawer] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selector = useSelector((state) => state);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const showHeaderElements = searchParams.hasOwnProperty('aiflow') ? false : true;
  const originDataList = selector?.originData.originData;
  const navbarButtonsData = selector?.navigationDataSlice?.navigationData;
  const isMobileMenuSidebarActive = selector?.navigationDataSlice?.isMobileMenuSidebarActive
  // console.log("navbarButtonsData", navbarButtonsData);

  const isDialogActive = selector.dialogActionInfo.isDialogStatus;
  const shareViewModeStatus = selector?.itineraryGeneralInfo?.isInViewMode;


  const handleEventDialogStatus = (dialogStatus) => {
    setOpen({ ...open, dialogStatus: false });
    if (dialogStatus === "agree") {
      dispatch(setSelectedoriginData(open.location));
      getSessionInfo();
      if (location.pathname === '/ai-shopping/' || location.pathname === "/") {
        dispatch(getAiShoppingInfoSlice({ urlKeyName: "chat_questionary_url" }));
      }
      if (location.pathname === '/ai-itinerary') {
        dispatch(resetSelectedPlanDataObjForNewTrip());
        navigate("/ai-itinerary");
      }
    }
  };

  const handleEventSessionDialogStatus = (eventType = "") => {
    dispatch(setDialogActionInfo(false));
    if (eventType === "agree") {
      getSessionInfo();
    } else {
      dispatch(setInputCtrlStatus(true));
    }
  };

  const handleRedirect = () => {
    window.open('https://www.cheapcaribbean.com/', '_blank');
  };

  const handleChangeNavigate = () => {
    window.location.href = "/";
  };

  const getSessionInfo = async () => {
    // const { status, data } = await dispatch(getSeesionInfo({ urlKeyName: "session_url" })).unwrap();
    // setTokenInfo(data);
    // if (status) {
    //   dispatch(setInputCtrlStatus(false));
    //   dispatch(clearUserCtrlData({ key_name: "aiAsstMessage", key_value: [], key_type: "session_clear" }));
    // }
    const resultAction = await dispatch(getSeesionInfo({ urlKeyName: "session_url" }));
    if (getSeesionInfo.fulfilled.match(resultAction)) {
      const data = resultAction.payload;
      setTokenInfo(data);
      dispatch(setInputCtrlStatus(false));
      dispatch(clearUserCtrlData({ key_name: "aiAsstMessage", key_value: [], key_type: "session_clear" }));
      dispatch(aiChatWelcomeMsg());
    }
  };

  const handleOriginSelect = (event, newValue) => {
    if (newValue && location.pathname === '/ai-itinerary') {
      handleEventDialogStatus("agree");
      return;
    }
    if (newValue && location.pathname !== '/ai-itinerary') {
      setOpen({ dialogStatus: true, location: newValue });
    }
  };

  useEffect(() => {
    const initialize = async () => {
      dispatch(getNaviagtionInfo())
      dispatch(setSelectedoriginData({ cityCode: "DFW", cityName: "Dallas/Fort Worth" }));
      const { status, data } = await dispatch(getAiShoppingInfoSlice({ urlKeyName: "chat_questionary_url" })).unwrap();
      if (data) {
        dispatch(originDataInfo(data.origins));
        if (locationInfo != undefined && locationInfo.apiStatus != undefined && locationInfo.apiStatus == true) {
          const filteredLocationData = data.origins.find((list) => list.cityCode === locationInfo.nearestAirport.MarketCode);
          if (filteredLocationData) {
            dispatch(setSelectedoriginData(filteredLocationData));
          } else {
            setModalOpenStatus(true);
            dispatch(setSelectedoriginData({ cityCode: "DFW", cityName: "Dallas/Fort Worth" }));
          }
        } else {
          setModalOpenStatus(true);
          dispatch(setSelectedoriginData({ cityCode: "DFW", cityName: "Dallas/Fort Worth" }));
        }
      }
    };

    initialize();
  }, [dispatch, locationInfo]);

  const getModalDesign = () => (
    (location.pathname === '/' || location.pathname === '/ai-shopping') && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={modalOpenStatus}
        autoHideDuration={6000}
        onClose={() => setModalOpenStatus(false)}
      >
        <SnackbarContent
          sx={{ backgroundColor: "white", color: "black" }}
          message={
            <>
              {"Default location for your AI search is set to "}
              <b>{"Dallas/Fort Worth."}</b> {"Feel free to change it."}
              <CloseIcon className="cursor-pointer" fontSize="small" onClick={() => setModalOpenStatus(false)} />
            </>
          }
        />
      </Snackbar>
    )
  );

  const confirmButton = () => (
    <Dialog
      open={open.dialogStatus}
      keepMounted
      onClose={() => handleEventDialogStatus("cancel")}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="dialog-content-padding-cls">
        <DialogContentText id="alert-dialog-slide-description">
          Changing the Origin resets the current conversation. Please confirm to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleEventDialogStatus("cancel")}>Cancel</Button>
        <Button data-testid="dialog_location_agree_id" id="dialog_location_agree_id" onClick={() => handleEventDialogStatus("agree")}>OK</Button>
      </DialogActions>
    </Dialog>
  );

  const confirmSession = () => (
    <Dialog
      open={isDialogActive}
      keepMounted
      onClose={() => handleEventSessionDialogStatus("cancel")}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="dialog-content-padding-cls">
        <DialogContentText id="alert-dialog-slide-description">
          This model's maximum context length is 16384 tokens. You have reached the limit. Please try a new session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="dialog_location_agree_id" onClick={() => handleEventSessionDialogStatus("agree")}>OK</Button>
      </DialogActions>
    </Dialog>
  );

  const onChangeDrawerStatus = (event, selectedNavButton) => {
    if (selectedNavButton?.link != "") {
      event.preventDefault();
      window.open(selectedNavButton.link, '_blank');
    } else {
      setSelectedDrawer(selectedNavButton)
    }
  }

  const handClickMobileMenuButton = () => {
    if (location.pathname === "/ai-itinerary/details") {
      dispatch(setMobileMenuActiveStatus({ isMobileMenuActive: true }))
    } else {
      dispatch(setHeaderMobileMenuSidebarActiveStatus({ isActive: true }))
    }
  }

  const handleCookiesOnClick = () => {

  }

  return (
    <>
      {/* {getModalDesign()} */}
      {confirmSession()}
      {confirmButton()}
      <section id="header-bar" className="header-section">
        <div className="header-section-child-container-cls">
          <div id="header-container" className="container bg-white">
            <div className="row">
              <div className="col-12 mobile-padding-cls">
                <div className={`d-flex align-items-center ${showHeaderElements ? "justify-content-between" : "justify-content-end"}`}>
                  {
                    showHeaderElements ? <>
                      <nav className="navbar navbar-expand-sm navbar-white p-0">
                        {/* {location.pathname === "/ai-itinerary/details" && (
                      <div onClick={() => { dispatch(setMobileMenuActiveStatus({ isMobileMenuActive: true })) }} className='trip-mobile-menu-icon me-2 me-sm-3'>
                        <img src={hamburgerMenu} alt='hamburgerMenu' width={30} height={30} />
                      </div>
                    )} */}
                        <div onClick={() => { handClickMobileMenuButton() }} className='trip-mobile-menu-icon me-2 me-sm-3'>
                          <img src={hamburgerMenu} alt='hamburgerMenu' width={30} height={30} />
                        </div>
                        <a className="navbar-brand">
                          <div className="logo" onClick={handleRedirect}>
                            <img src={reactLogo} alt="Cheap Caribbean vacation" />
                          </div>
                        </a>

                      </nav>
                      <ul className="navbar-buttons-list">
                        {navbarButtonsData?.map((eachButton) =>
                          <li className="navbar-each-list-btn" key={`${eachButton?.id}-${eachButton?.name}`}>
                            <Button variant="contained" startIcon={<Icon icon={eachButton?.icon} />} endIcon={eachButton?.link == "" ? <Icon icon="iconamoon:arrow-down-2" /> : ''} className="navbar-button-cls" onClick={(event) => onChangeDrawerStatus(event, eachButton)}>
                              {eachButton?.name}
                            </Button>
                          </li>
                        )}

                      </ul>
                    </> : null
                  }

                  {!shareViewModeStatus && originDataList?.length > 0 && (
                    <div className="yourOrigin d-flex gap-3 gap-lg-3 align-items-center">
                      <Box className="yourOrigin">
                        <Autocomplete
                          data-testid="originselectbox"
                          value={selector.originData.originSelected}
                          onChange={handleOriginSelect}
                          className="custom_Autocomplete"
                          options={originDataList}
                          autoHighlight
                          getOptionLabel={(option) => `${option.cityName} (${option.cityCode})`}
                          renderOption={(props, option) => (
                            <Box className="custom_Autocomplete_dropdown" component="li" {...props}>
                              {`${option.cityName} (${option.cityCode})`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Origin"
                              inputProps={{
                                ...params.inputProps,
                                style: { fontSize: "14px", padding: '5px' },
                                autoComplete: 'new-password'
                              }}
                            />
                          )}
                        />
                      </Box>
                      <div>
                        <a onClick={handleChangeNavigate} className="btn-cancel">
                          <img src={closeBtn} alt="closeBtn" />
                        </a>
                      </div>
                    </div>
                  )}
                  {originDataList?.length === 0 && !shareViewModeStatus && (
                    <SkeletonInputCtrl enableTyping={false} variantType="rounded" width={250} height={48} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      { <MobileMenuSidebar onChangeDrawerStatus={onChangeDrawerStatus} handleCookiesOnClick={handleCookiesOnClick} />}
      {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings d-none">Cookie Center</button> */}
      {/* {isMobileMenuSidebarActive ? <MobileMenuSidebar onChangeDrawerStatus={onChangeDrawerStatus} /> : null} */}
      {navbarButtonsData?.length > 0 && navbarButtonsData?.map((eachButton) => <SideDrawer key={eachButton?.id} open={selectedDrawer ? selectedDrawer?.id === eachButton?.id : false} position={eachButton?.align} selectedDrawer={selectedDrawer} setSelectedDrawer={setSelectedDrawer} />)}
    </>
  );
};

export default Header;

