import * as React from 'react';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import DialogContent from '@mui/joy/DialogContent';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
// import ModalClose from '@mui/joy/ModalClose';
import { Icon } from "@iconify/react";

import "./../assets/css/header.scss";


const SideDrawer = ({ key, open = false, position = "left", selectedDrawer = false, setSelectedDrawer = () => { } }) => {
    return (
        <div className='side-drawer-main-cls' key={`drawer-${key}`}>
            <Drawer open={open} onClose={() => setSelectedDrawer(false)} size={'md'} anchor={position} className='nav-side-drawer-container-cls'>
                <a onClick={() => setSelectedDrawer(false)} className="close-nav-menu" aria-label="Close Destinations Menu"><Icon icon="fa:close" /></a>
                <DialogContent className={`mt-3 ${selectedDrawer?.align === 'right' ? 'right-side-drawer-content-cls' : ''}`}>
                    {
                        selectedDrawer?.maincontent?.map((element, index) =>
                            <>
                                <h2 className='drawer-title-cls'>{element.sectionname}</h2>
                                <List sx={{ paddingLeft: '0px', marginBottom: '20px' }} >
                                    {element.sectioncontent?.map((subelement, index) => (
                                        <ListItem className={`side-drawer-list-item-cls ${subelement?.subcontent?.length > 0 ? 'list-subelement-subcontent-cls' : ''}`} key={`${index}-${subelement?.name}`} sx={{ paddingLeft: '0px' }}>
                                            <ListItemButton onClick={() => {
                                                setSelectedDrawer(false)
                                                window.open(subelement.link, '_blank');
                                            }} sx={{ fontWeight: subelement?.style || '', minHeight: subelement?.subcontent?.length > 0 ? '0px' : '' }} className={`${subelement?.subcontent?.length > 0 ? "py-0  mb-0" : 'py-2'} side-drawer-list-item-btn-cls`}>
                                                {subelement?.icon ? <i className={subelement?.icon}></i> : null}
                                                {subelement?.name}
                                            </ListItemButton>

                                            {
                                                subelement?.subcontent?.length > 0 ? <div className='sidenav-sub-menu-items-container-cls'>{subelement?.subcontent?.map((subEachElement, index) => (
                                                    <ListItemButton onClick={() => {
                                                        setSelectedDrawer(false)
                                                        window.open(subEachElement?.link, '_blank');
                                                    }} sx={{ fontWeight: subEachElement?.style || '' }} className={`side-drawer-list-item-btn-cls sidenav-sub-menu-items py-2 `} key={`${index}-${subEachElement?.name}`}>
                                                        {subEachElement?.icon ? <i className={subEachElement?.icon}></i> : null}
                                                        {subEachElement?.name}
                                                    </ListItemButton>

                                                ))}</div> : null}

                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )
                    }
                </DialogContent>


                {selectedDrawer?.footercontent?.length > 0 ? <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        p: 1.5,
                        pb: 2,
                        pt: 0.5,
                        // borderTop: '1px solid',
                        // borderColor: 'divider',
                    }}
                >
                    {/* <Avatar size="lg" /> */}
                    <div className='d-flex align-items-center'>
                        {selectedDrawer?.footercontent[0]?.link ? <a href={selectedDrawer?.footercontent[0]?.link || ''} target='_blank' rel="noreferrer" className={`view-all-footer-text-cls`} style={{ fontWeight: selectedDrawer?.footercontent[0]?.style || "" }}>{selectedDrawer?.footercontent[0]?.name} <Icon icon="mdi:arrow-right-bold" className='' /></a> : <Typography level="title-md" className={`view-all-footer-text-cls`} sx={{ fontWeight: selectedDrawer?.footercontent[0]?.style || "" }}>{selectedDrawer?.footercontent[0]?.name} <Icon icon="mdi:arrow-right-bold" className='' /></Typography>}

                    </div>
                </Box> : null}
            </Drawer>
        </div>
    )
}

export default SideDrawer