import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import BouncingDotsLoader from '../loader/BouncingDotsLoader';
const SkeletonInputCtrl = (props) => {
    const { variantType = "rounded", width = 0, height = 0, borderRadius = '5px', enableTyping = false } = props;
    return <>
        {enableTyping && <BouncingDotsLoader />}
        <Skeleton variant={variantType} width={width} height={height} style={{ borderRadius: borderRadius }} />
    </>
}
export default SkeletonInputCtrl;