import {
  createSlice
} from "@reduxjs/toolkit";
import moment from "moment";



//prepareManageItineraryItem
const prepareManageItineraryItem = (item, isUpdateFlow) => {
  return {
    id: isUpdateFlow ? item.id : 0,
    displayOrder: 2,
    activityType: 3, //item?.itemType || null,
    activityName: item?.name || item?.activityName || '',
    activityProvider: item?.activityProvider || '',
    activityDesc: item?.description || item?.activityDesc || item?.address || '',
    startTime: item?.startTime || '',
    endTime: item?.endTime || '',
    activityDuration: item?.duration || item?.activityDuration || '',
    activityPrice: item?.price || item?.activityPrice || 0,
    travelTime: item?.travelTime || '',
    imageURL: item?.imageURL || item?.activityImage || '',
    bookLink: item?.bookLink || '',
    lunchTime: item?.lunchTime || '',
    lunchDuration: 0,
    dinnerTime: item?.dinnerTime || '',
    dinnerDuration: 0,
    itineraryType: item?.itineraryType || item?.itineraryType || '',
    hotelCode: item?.hotelCode || '',
    productId: item?.productId || '',
  }
}


const yourStayActivity = (activity) => {
  return {
    id: 0,
    destinationName: null,
    hotelCode: "",
    productId: "",
    sessionId: activity.sessionId,
    uid: activity.uid,
    displayOrder: 1,
    activityType: 3,
    activityName: "NO HOTEL SELECTED",
    activityProvider: "YOUR STAY",
    activityDesc: "WANT TO BOOK HOTEL?",
    startTime: "",
    endTime: "",
    activityDuration: "Reach YS Falls Only by 12:00 AM",
    distance: "0 Miles",
    activityPrice: 0,
    travelTime: "",
    activityImage: "",
    bookLink: "",
    active: true,
    lunchTime: null,
    lunchDuration: 0,
    dinnerTime: null,
    dinnerDuration: 0,
    itineraryType: 1,
    isFreeTimeSlot: false,
    isHotelCard: true,
    isDraggable: false,
    isLunch: false,
    isDinner: false,
    latitude: null,
    longitude: null,
    additionalTimeSlots: null
  }
}


const freeTimeActivity = (activity) => {
  return {
    id: 0,
    destinationName: null,
    hotelCode: null,
    productId: "",
    sessionId: activity.sessionId,
    uid: activity.uid,
    displayOrder: 2,
    activityType: 3,
    activityName: "Enjoy @Beach",
    activityProvider: "Free Time",
    activityDesc: "Relax and Rejuvenate at the Beach",
    startTime: activity?.startTime,
    endTime: activity?.endTime,
    activityDuration: activity?.activityDuration,
    distance: "0 Miles",
    activityPrice: 0,
    travelTime: "",
    activityImage: "",
    bookLink: "",
    active: true,
    lunchTime: null,
    lunchDuration: 0,
    dinnerTime: null,
    dinnerDuration: 0,
    itineraryType: 2,
    isFreeTimeSlot: true,
    isHotelCard: false,
    isDraggable: false,
    isLunch: false,
    isDinner: false,
    latitude: null,
    longitude: null,
    additionalTimeSlots: null
  }
}


const selectedPlanDataForNewTrip = {
  sessionId: '',
  destination: {
    cityCode: '',
    cityName: '',
  },
  startDate: '',
  endDate: '',
  dayStartTime: "08:30 AM", //its default time
  dayEndTime: "04:30 PM", //its default time
  lengthOfStay: 3, // default - 3 
  themes: [],
  searchTerm: '',
  energyLevel: {
    relaxing: 50,
    activities: 50 // default - slider value
  },
  lunch: {
    isIncluded: false,
    timeSlot: '01:00 PM',
  },
  dinner: {
    isIncluded: false,
    timeSlot: '09:00 PM',
  },
  resetKey: Math.random().toString()
};

export const initialState = {
  // View Mode
  isInViewMode: false,
  //constants 
  activityTypes: {
    lunch: 1, //not draggable
    dinner: 2, //not draggable
    activity: 3
  },

  itineraryTypes: {
    packages: 1,
    activities: 2,
    attractions: 3,
    restaurants: 4
  },

  itineraryTypeNames: {
    packages: 'Packages',
    activities: 'Activities',
    attractions: 'Attractions',
    restaurants: 'Restaurants'
  },

  // Mobile Menu Status
  isMobileMenuActive: false,
  // loading status
  isLoading: false,

  //state
  sessionId: '', // default - empty
  chatItineraryData: [],
  itineraryData: {
    title: '',
    subTitle: '',
    user: null,
    assistant: "",
    hotelCode: "",
  },
  exploreItineraryData: {
    packages: [],
    activities: [],
    attractions: [],
    restaurants: [],
  },

  selectedPlanDataForNewTrip,
  existingSelectedPlanDataForNewTrip: selectedPlanDataForNewTrip,

  //Day and Time Slot PopOver

  dayTimeSlotPopOver: {
    popoverFor: '',
    popoverTarget: null,
    openPopover: false,
    timeSlots: [],
    days: [],
    showDaysRow: false,
    showCustomTimeSlot: false,
    showTimeSlots: false,
    existed: {
      day: '',
      time: '',
      startTime: '',
      endTime: '',
      data: {}
    }
  },

  //explore packages model
  exploreItinerary: {
    openModal: false,
    tabs: [{
      label: 'Packages',
      value: 1,
    },
    {
      label: 'Activities',
      value: 2,
    },
    // {
    //   label: 'Attractions',
    //   value: 3,
    // },
      // {
      //   label: 'Restaurants',
      //   value: 4,
      // }
    ],
    selectedTab: 1,

    selectedPackage: {},
    selectedActivities: [],
    selectedAttractions: [],
    selectedRestaurants: [],

  },


  relaxingAndActivitiesSlider: {
    openModal: false
  },

  displayShowDealsBlock: true,
  showDeals: false,
  onApplyFilter: {
    applyKey: Math.random(),
    applyFilter: false,
    hasReloadedData: false,
  },

  onSelectItineraryPlan: {
    planKey: Math.random(),
    day: '',
    scrollToId: '',
    expandedIndex: [0],
    onApplyPlan: false
  },

  manageItineraryPayload: {},

  confirmationActivity: {
    width: 500,
    type: 'delete',
    showModal: false,
    selectedActivity: {},
    details: {
      title: 'Confirmation',
      description: 'Are you sure you want to delete this activity?',
      noBtnText: 'NO',
      yesBtnText: 'Yes'
    }
  },

  editActivity: {
    showModal: false,
    selectedActivity: {},
    existingSelectedActivity: {}
  },

  replaceActivity: {
    showModal: false,
    selectedActivity: {},
    selectedActivityForReplace: {}
  },

  dayItinerary: {
    activityPercentage: 50,
    day: {}
  },

  addActivity: {
    showModal: false,
    selectedActivity: {}
  },
  downloadItinerary: [],
  updateActivityDisplayOrderPayload: {},

  refreshAccardialDetails: Math.random(),

  confirmToTimeSlotConflict: {
    confirmToReplaceTimeSlots: false,
    // confirmToDeleteActivities: false,
    activities: [],
    applyKey: Math.random(),
  }
}

const itineraryGeneratorSlice = createSlice({
  name: 'itineraryGeneratorInfo',
  initialState,
  reducers: {
    //sessionId
    setSessionId(state, action) {
      state.sessionId = action.payload.sessionId;
    },

    setSelectedPlanDataForNewTrip(state, action) {
      state.selectedPlanDataForNewTrip[action.payload.filedName] = action.payload.value;
    },
    setSelectedPlanDataObjForNewTrip(state, action) {
      state.selectedPlanDataForNewTrip = action.payload.selectedPlanDataForNewTrip;
      state.existingSelectedPlanDataForNewTrip = state.selectedPlanDataForNewTrip;
    },
    resetSelectedPlanDataObjForNewTrip(state, action) {
      state.selectedPlanDataForNewTrip = {
        ...initialState.selectedPlanDataForNewTrip,
        resetKey: Math.random().toString()
      };
      state.existingSelectedPlanDataForNewTrip = state.selectedPlanDataForNewTrip;
    },

    setChatItineraryData(state, action) {
      state.chatItineraryData = action.payload.chatItineraryData;

      //updated selected plan data to existing selected plan data
      state.existingSelectedPlanDataForNewTrip = state.selectedPlanDataForNewTrip;
    },

    setItineraryData(state, action) {
      state.itineraryData = action.payload.itineraryData;
      state.existingSelectedPlanDataForNewTrip = state.selectedPlanDataForNewTrip;
    },
    // setShowAssistantMessage(state, action) {
    //   state.itineraryData.showMessage = action.payload.showMessage;
    //   state.itineraryData.assistant = '';
    // },

    //explored itinerary data
    setExploreItineraryData(state, action) {
      state.exploreItineraryData.packages = action.payload.packages;
      state.exploreItineraryData.activities = action.payload.activities;
      state.exploreItineraryData.attractions = action.payload.attractions;
      state.exploreItineraryData.restaurants = action.payload.restaurants;
    },

    //display show deals block
    setDisplayShowDealsBlock(state, action) {
      state.displayShowDealsBlock = action.payload.displayShowDealsBlock;
    },

    //set show deals
    setShowDeals(state, action) {
      state.showDeals = action.payload.showDeals;
    },

    setRelaxingAndActivitiesSlider(state, action) {
      state.relaxingAndActivitiesSlider.openModal = action.payload.openModal;
    },

    //exploreItinerary
    setExploreItinerary(state, action) {
      state.exploreItinerary.openModal = action.payload.openModal;
    },

    //set selected tab
    setExploreItinerarySelectedTab(state, action) {
      state.exploreItinerary.selectedTab = action.payload.selectedTab;
    },

    //selected package
    setExploreItinerarySelectedPackage(state, action) {
      state.exploreItinerary.selectedPackage = { ...action.payload.selectedPackage, itineraryType: state.itineraryTypes.packages, activityProvider: state.itineraryTypeNames.packages };
    },

    //selected activities
    setExploreItinerarySelectedActivities(state, action) {
      const isActivitySelected = state.exploreItinerary.selectedActivities.find((item) => item.id === action.payload.selectedActivity.id);
      if (isActivitySelected) {
        state.exploreItinerary.selectedActivities = state.exploreItinerary.selectedActivities.filter((item) => item.id !== action.payload.selectedActivity.id);
      } else {
        state.exploreItinerary.selectedActivities = [
          ...state.exploreItinerary.selectedActivities,
          {
            ...action.payload.selectedActivity,
            itineraryType: state.itineraryTypes.activities,
            activityProvider: state.itineraryTypeNames.activities,
          }
        ];
      }
    },

    //selected attractions
    setExploreItinerarySelectedAttractions(state, action) {
      const isAttractionSelected = state.exploreItinerary.selectedAttractions.find((item) => item.id === action.payload.selectedAttraction.id);
      if (isAttractionSelected) {
        state.exploreItinerary.selectedAttractions = state.exploreItinerary.selectedAttractions.filter((item) => item.id !== action.payload.selectedAttraction.id);
      } else {
        state.exploreItinerary.selectedAttractions = [
          ...state.exploreItinerary.selectedAttractions,
          {
            ...action.payload.selectedAttraction,
            itineraryType: state.itineraryTypes.attractions,
            activityProvider: state.itineraryTypeNames.attractions,
          }
        ];
      }
    },

    //selected restaurants
    setExploreItinerarySelectedRestaurants(state, action) {
      const isRestaurantSelected = state.exploreItinerary.selectedRestaurants.find((item) => item.id === action.payload.selectedRestaurant.id);
      if (isRestaurantSelected) {
        state.exploreItinerary.selectedRestaurants = state.exploreItinerary.selectedRestaurants.filter((item) => item.id !== action.payload.selectedRestaurant.id);
      } else {
        state.exploreItinerary.selectedRestaurants = [
          ...state.exploreItinerary.selectedRestaurants,
          {
            ...action.payload.selectedRestaurant,
            itineraryType: state.itineraryTypes.restaurants,
            activityProvider: state.itineraryTypeNames.restaurants,
          }
        ];
      }
    },


    //set on apply when clicks on apply button
    setOnApplyFilter(state, action) {
      state.onApplyFilter.applyKey = Math.random();
      state.onApplyFilter.applyFilter = action.payload.applyFilter;
      state.onApplyFilter.hasReloadedData = action.payload.hasReloadedData;
    },

    //on select onSelectItineraryPlan
    setOnSelectItineraryPlan(state, action) {
      state.onSelectItineraryPlan.planKey = Math.random();
      state.onSelectItineraryPlan.day = action.payload.day;
      state.onSelectItineraryPlan.scrollToId = `day${action.payload.day}`;
      state.onSelectItineraryPlan.onApplyPlan = action.payload.onApplyPlan;

      if (action.payload.expanded) {
        state.onSelectItineraryPlan.expandedIndex = state.onSelectItineraryPlan.expandedIndex.filter((item) => item !== action.payload.expandedIndex);
      } else {
        state.onSelectItineraryPlan.expandedIndex = [...state.onSelectItineraryPlan.expandedIndex, action.payload.expandedIndex];
      }
    },

    //collapseAllActivityAccordians
    setCollapseAllActivityAccordians(state, action) {
      state.onSelectItineraryPlan.expandedIndex = [];
    },


    //expandAllActivityAccordians
    setExpandAllActivityAccordians(state, action) {
      state.onSelectItineraryPlan.planKey = Math.random();
      const itineraryItems = state.chatItineraryData;
      state.onSelectItineraryPlan.expandedIndex = [...Array(itineraryItems.length).keys()]
    },

    //collapseAllActivityAccordians
    setExpandActivityAccordian(state, action) {
      state.onSelectItineraryPlan.expandedIndex = action.payload?.expandedIndex ? [action.payload?.expandedIndex] : [0];
    },

    setDayTimeSlotPopOverData(state, action) {
      const {
        popoverFor = '', days = [], timeSlots = [], popoverTarget = null,
        openPopover = false, showDaysRow = false, showTimeSlots = false, showCustomTimeSlot = false,
        existed = {
          day: '',
          time: '',
          startTime: '',
          endTime: '',
          data: {}
        }
      } = action.payload;

      state.dayTimeSlotPopOver.popoverFor = popoverFor;
      state.dayTimeSlotPopOver.popoverTarget = popoverTarget;
      state.dayTimeSlotPopOver.openPopover = openPopover;
      state.dayTimeSlotPopOver.days = days;
      state.dayTimeSlotPopOver.timeSlots = timeSlots;
      state.dayTimeSlotPopOver.showDaysRow = showDaysRow;
      state.dayTimeSlotPopOver.showTimeSlots = showTimeSlots;
      state.dayTimeSlotPopOver.showCustomTimeSlot = showCustomTimeSlot;
      state.dayTimeSlotPopOver.existed.startTime = existed.startTime;
      state.dayTimeSlotPopOver.existed.endTime = existed.endTime;
      state.dayTimeSlotPopOver.existed.day = existed.day;
      state.dayTimeSlotPopOver.existed.time = existed.time;
      state.dayTimeSlotPopOver.existed.data = existed.data;

    },

    //set on save day and timeslots
    setOnSaveDayAndTimeSlot(state, action) {

      const { selectedPlanDataForNewTrip, exploreItinerary, dayTimeSlotPopOver } = state;


      const { day, timeSlot, popoverFor, customTimeSlot } = action.payload;

      if (customTimeSlot?.isChanged) {
        if (popoverFor === 'dayStartTime' || popoverFor === 'dayEndTime') {
          selectedPlanDataForNewTrip.dayStartTime = customTimeSlot.startTime;
          selectedPlanDataForNewTrip.dayEndTime = customTimeSlot.endTime;
        } else if (popoverFor === 'attractions') {
          const selectedIndex = exploreItinerary.selectedAttractions.findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          
          if (selectedIndex !== -1) {
            const existedItem = exploreItinerary.selectedAttractions[selectedIndex];
            existedItem['day'] = day;
            existedItem['startTime'] = customTimeSlot.startTime;
            existedItem['endTime'] = customTimeSlot.endTime;

            exploreItinerary.selectedAttractions[selectedIndex] = existedItem;
          }
        } else if (popoverFor === 'restaurants') {
          const selectedIndex = exploreItinerary.selectedRestaurants.findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          if (selectedIndex !== -1) {
            const existedItem = exploreItinerary.selectedRestaurants[selectedIndex];
            existedItem['day'] = day;
            existedItem['startTime'] = customTimeSlot.startTime;
            existedItem['endTime'] = customTimeSlot.endTime;

            exploreItinerary.selectedRestaurants[selectedIndex] = existedItem;
          }
        } else if (popoverFor === 'activities') {
          const selectedIndex = exploreItinerary.selectedActivities.findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          if (selectedIndex !== -1) {
            const existedItem = exploreItinerary.selectedActivities[selectedIndex];
            existedItem['day'] = day;
            existedItem['startTime'] = customTimeSlot.startTime;
            existedItem['endTime'] = customTimeSlot.endTime;
            exploreItinerary.selectedActivities[selectedIndex] = existedItem;
          }
        } else if (popoverFor === 'editActivity') {

          let selectedItineraryTypeName = 'selectedActivities';
          if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.activities) {
            selectedItineraryTypeName = 'selectedActivities';
          } else if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.attractions) {
            selectedItineraryTypeName = 'selectedAttractions';
          } else if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.restaurants) {
            selectedItineraryTypeName = 'selectedRestaurants';
          }

          const selectedIndex = exploreItinerary[selectedItineraryTypeName].findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          if (selectedIndex !== -1) {
            const existedItem = exploreItinerary[selectedItineraryTypeName][selectedIndex];
            existedItem['day'] = day;
            existedItem['startTime'] = customTimeSlot.startTime;
            existedItem['endTime'] = customTimeSlot.endTime;
            state.editActivity.selectedActivity = existedItem;

            exploreItinerary[selectedItineraryTypeName][selectedIndex] = existedItem;

          }
        }

        return
      }

      switch (popoverFor) {
        case 'dayStartTime':
          selectedPlanDataForNewTrip.dayStartTime = timeSlot.time;
          break;
        case 'dayEndTime':
          selectedPlanDataForNewTrip.dayEndTime = timeSlot.time;
          break;
        case 'lunch':
          selectedPlanDataForNewTrip.lunch.timeSlot = timeSlot.time;
          break;
        case 'dinner':
          selectedPlanDataForNewTrip.dinner.timeSlot = timeSlot.time;
          break;
        case 'activities':
          const selectedIndex = exploreItinerary.selectedActivities.findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          if (selectedIndex !== -1) {
            const activity = exploreItinerary.selectedActivities[selectedIndex];
            // const _timeSlot = activity?.timeSlots?.find((item) => item.startTime === timeSlot.time);
            const _timeSlot = timeSlot.time.split('-').map((item) => item.trim());
            activity['day'] = day;
            [activity['startTime'], activity['endTime']] = _timeSlot || ['', ''];
            exploreItinerary.selectedActivities[selectedIndex] = activity;
          }
          break;
        case 'editActivity':
          let selectedItineraryTypeName = 'selectedActivities';
          if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.activities) {
            selectedItineraryTypeName = 'selectedActivities';
          } else if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.attractions) {
            selectedItineraryTypeName = 'selectedAttractions';
          } else if (state.editActivity.selectedActivity.itineraryType === state.itineraryTypes.restaurants) {
            selectedItineraryTypeName = 'selectedRestaurants';
          }

          const editActivityIndex = exploreItinerary[selectedItineraryTypeName].findIndex((item) => item.id === dayTimeSlotPopOver.existed.data.id);
          if (editActivityIndex !== -1) {
            const existedItem = exploreItinerary[selectedItineraryTypeName][editActivityIndex];
            existedItem['day'] = day;
            const _timeSlot = timeSlot.time.split('-').map((item) => item.trim());
            [existedItem['startTime'], existedItem['endTime']] = _timeSlot || ['', ''];
            state.editActivity.selectedActivity = existedItem;

            exploreItinerary[selectedItineraryTypeName][editActivityIndex] = existedItem;

          }
          break;
        default:
          break;
      }

    },


    //preparing Payload for manage itinerary
    setPrepareManageItineraryPayload(state, action) {

      const isUpdateFlow = action.payload?.isUpdateFlow || false;

      const payload = {
        sessionId: state.sessionId,
        destinationCode: state.selectedPlanDataForNewTrip.destination.cityCode,
        destinationName: state.selectedPlanDataForNewTrip.destination.cityName,
        dayItineraryItems: []
      }

      const {
        selectedPackage,
        selectedActivities,
        selectedAttractions,
        selectedRestaurants
      } = state.exploreItinerary;

      const dayItineraryItems = [];

      if (selectedPackage && selectedPackage.hotelCode) {
        dayItineraryItems.push({
          activityDate: moment(selectedPackage.startDate).format('YYYY-MM-DD'),
          dayNumber: 1,
          hotelCode: selectedPackage.hotelCode,
          activityPercentage: `${state.selectedPlanDataForNewTrip.energyLevel.activities}`,
          itineraryItems: [prepareManageItineraryItem(selectedPackage, isUpdateFlow)]
        });
      }

      [...selectedActivities, ...selectedAttractions, ...selectedRestaurants].forEach((item) => {
        const dayIndex = dayItineraryItems.findIndex((day) => day.dayNumber === item.day.dayNumber);
        if (dayIndex !== -1) {
          dayItineraryItems[dayIndex]['itineraryItems'].push(prepareManageItineraryItem(item, isUpdateFlow));
        } else {
          dayItineraryItems.push({
            activityDate: moment(item.day.activityDate).format('YYYY-MM-DD'),
            dayNumber: item.day.dayNumber,
            activityPercentage: item.day.activityPercentage,
            itineraryItems: [prepareManageItineraryItem(item, isUpdateFlow)]
          })
        }
      });

      payload.dayItineraryItems = dayItineraryItems;
      state.manageItineraryPayload = payload;
    },

    resetExploreSelectedDetails(state, action) {
      state.manageItineraryPayload = {};
      state.exploreItinerary.selectedPackage = {};
      state.exploreItinerary.selectedActivities = [];
      state.exploreItinerary.selectedAttractions = [];
      state.exploreItinerary.selectedRestaurants = [];
    },


    setConfirmationActivity(state, action) {
      const { width = 'sm', type = 'delete', showModal, selectedActivity,
        details: {
          title = 'Confirmation',
          description = 'Are you sure you want to delete this activity?',
          noBtnText = 'NO',
          yesBtnText = 'Yes'
        }
      } = action.payload;

      state.confirmationActivity.width = width;
      state.confirmationActivity.type = type;
      state.confirmationActivity.showModal = showModal;
      state.confirmationActivity.selectedActivity = selectedActivity;
      state.confirmationActivity.details = { title, description, noBtnText, yesBtnText }

    },

    //setDeletedActivityReplaceWithFreeTimeCard
    setDeletedActivityReplaceWithFreeTimeCard(state, action) {
      const { selectedActivity } = action.payload;

      if (selectedActivity.isHotelCard) {

        state.chatItineraryData = state.chatItineraryData.map((chatItineraryDay) => {
          chatItineraryDay.itineraryItems = chatItineraryDay.itineraryItems.map((activityItem) => {
            if (activityItem.isHotelCard) {
              activityItem = yourStayActivity(selectedActivity);
            }
            return activityItem;
          });
          return chatItineraryDay;
        });
      } else {
        const chatItineraryDayIndex = state.chatItineraryData.findIndex((item) => item.dayNumber === selectedActivity?.day?.dayNumber);
        if (chatItineraryDayIndex !== -1) {
          const chatItineraryDay = state.chatItineraryData[chatItineraryDayIndex];
          const activityIndex = chatItineraryDay.itineraryItems.findIndex((item) => item.uid === selectedActivity.uid);
          if (activityIndex !== -1) {
            chatItineraryDay.itineraryItems[activityIndex] = freeTimeActivity(selectedActivity);
          }
        }
      }
    },

    //selectedDayItinerary
    setDayItinerary(state, action) {
      state.dayItinerary.activityPercentage = action.payload.activityPercentage;
      state.dayItinerary.day = action.payload.day;
    },

    //editActivity
    setEditActivity(state, action) {
      state.editActivity.showModal = action.payload.showModal;
      state.editActivity.selectedActivity = action.payload.selectedActivity;
      state.editActivity.existingSelectedActivity = action.payload.selectedActivity;

      if (action.payload.selectedActivity?.id) {
        if (action.payload.selectedActivity?.itineraryType === state.itineraryTypes.activities) {
          state.exploreItinerary.selectedActivities = [action.payload.selectedActivity];
        } else if (action.payload.selectedActivity?.itineraryType === state.itineraryTypes.attractions) {
          state.exploreItinerary.selectedAttractions = [action.payload.selectedActivity];
        } else {
          state.exploreItinerary.selectedRestaurants = [action.payload.selectedActivity];
        }
      } else {
        state.exploreItinerary.selectedActivities = [];
        state.exploreItinerary.selectedAttractions = [];
        state.exploreItinerary.selectedRestaurants = [];
      }
    },

    //replaceActivity
    setReplaceActivity(state, action) {
      state.replaceActivity.showModal = action.payload.showModal;
      state.replaceActivity.selectedActivity = action.payload?.selectedActivity || {};
      state.replaceActivity.selectedActivityForReplace = action.payload?.selectedActivityForReplace || {};
    },

    //addActivity
    setAddActivity(state, action) {
      state.addActivity.showModal = action.payload.showModal;
      state.addActivity.selectedActivity = action.payload.selectedActivity;
    },

    //updateActivityDisplayOrderPayload
    setUpdateActivityDisplayOrderPayload(state, action) {
      state.updateActivityDisplayOrderPayload = action.payload;
    },

    //setConfirmationActivity
    setConfirmationTimeSlotConflict(state, action) {
      state.confirmToTimeSlotConflict.confirmToReplaceTimeSlots = action.payload.confirmToReplaceTimeSlots;
      state.confirmToTimeSlotConflict.applyKey = Math.random();
    },

    setConfirmationConflictedActivities(state, action) {
      const newlySelectedActivities = action.payload.activities.filter((item) => {
        const existedAct = state.confirmToTimeSlotConflict.activities.find((activity) => activity.uid === item.uid);
        return !existedAct;
      })

      state.confirmToTimeSlotConflict.activities = [
        ...state.confirmToTimeSlotConflict.activities,
        ...newlySelectedActivities
      ]
    },

    resetConfirmationConflictedActivities(state, action) {
      state.confirmToTimeSlotConflict.activities = []
    },
    //downloadActivity
    setDownloadActivityData(state, action) {
      const data = action.payload;
      const f = [];
      data.forEach(element => {
        const itemsData = element.itineraryItems.filter(f => f.isFreeTimeSlot !== true);
        f.push({
          ...element,
          itineraryItems: itemsData
        })
      });
      state.downloadItinerary = f;
    },
    // View Mode 
    setInViewMode(state, action) {
      state.isInViewMode = action.payload;
    },

    setLoadingStatus(state, action) {
      state.isLoading = action.payload.loading
    },

    // Mobile Menu Status
    setMobileMenuActiveStatus(state, action) {
      state.isMobileMenuActive = action.payload.isMobileMenuActive
    },

    //refreshAccardialDetails
    setRefreshAccardialDetails(state, action) {
      state.refreshAccardialDetails = Math.random();
    },

    //reset state
    setResetState(state, action) {
      state = initialState;
    },

  },
});



export const {
  setSessionId,
  setSelectedOrigin,
  setSelectedPlanDataForNewTrip,
  setSelectedPlanDataObjForNewTrip,
  resetSelectedPlanDataObjForNewTrip,
  setRelaxingAndActivitiesSlider,
  setChatItineraryData,
  setItineraryData,
  // setShowAssistantMessage,
  setExploreItineraryData,
  setExploreItinerary,
  setDayTimeSlotPopOverData,
  setOnSaveDayAndTimeSlot,
  setExploreItinerarySelectedTab,
  setExploreItinerarySelectedPackage,
  setExploreItinerarySelectedActivities,
  setExploreItinerarySelectedAttractions,
  setExploreItinerarySelectedRestaurants,
  resetExploreSelectedDetails,
  setPrepareManageItineraryPayload,
  setDisplayShowDealsBlock,
  setShowDeals,
  setOnApplyFilter,
  setOnSelectItineraryPlan,
  setCollapseAllActivityAccordians,
  setExpandAllActivityAccordians,
  setExpandActivityAccordian,
  setConfirmationActivity,
  setDeletedActivityReplaceWithFreeTimeCard,
  setDayItinerary,
  setEditActivity,
  setReplaceActivity,
  setAddActivity,
  setUpdateActivityDisplayOrderPayload,
  setConfirmationTimeSlotConflict,
  setConfirmationConflictedActivities,
  resetConfirmationConflictedActivities,
  setDownloadActivityData,
  setInViewMode,
  setResetState,
  setLoadingStatus,
  setMobileMenuActiveStatus,
  setRefreshAccardialDetails,
} = itineraryGeneratorSlice.actions;

export default itineraryGeneratorSlice.reducer;