import React, { useEffect, useRef } from 'react';
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { setHeaderMobileMenuSidebarActiveStatus } from "../store/slices/navigationSlice";
import "./../assets/css/header.scss";



const MobileMenuSidebar = ({ onChangeDrawerStatus = () => { } }) => {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const sidebarRef = useRef(null);

    const navbarButtonsData = selector?.navigationDataSlice?.navigationData;
    const isMobileMenuSidebarActive = selector?.navigationDataSlice?.isMobileMenuSidebarActive;
    const showCookieCenterBtn = selector?.aiShoppingInfo?.responseData?.isCookieCenterEnabled;
    

    const handleCloseMobileMenuSidebar = () => {
        dispatch(setHeaderMobileMenuSidebarActiveStatus({ isActive: false }))
    }

    useEffect(() => {

        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                dispatch(setHeaderMobileMenuSidebarActiveStatus({ isActive: false }))
            }
        }

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMobileMenuSidebarActive]);

    return (
        <div className={`mobile-navbar-menu-list-container-cls position-relatvie shadow-sm ${isMobileMenuSidebarActive ? 'active' : ''}`} ref={sidebarRef}>
            <a onClick={() => handleCloseMobileMenuSidebar()} className="mobile-close-nav-menu" aria-label="Close Destinations Menu"><Icon icon="fa:close" /></a>
            <hr />
            <ul className="mobile-navbar-menu-list">
                {navbarButtonsData?.map((eachButton) =>
                    <li className="navbar-each-list-btn my-2" key={`${eachButton?.id}-${eachButton?.name}`}>
                        <Button variant="contained" startIcon={<Icon icon={eachButton?.icon} />} endIcon={eachButton?.link == "" ? <Icon icon="iconamoon:arrow-down-2" /> : ''} className="navbar-button-cls" onClick={(event) => { dispatch(setHeaderMobileMenuSidebarActiveStatus({ isActive: false })); onChangeDrawerStatus(event, eachButton) }}>
                            {eachButton?.name}
                        </Button>
                    </li>
                )}
            </ul>
            {
                showCookieCenterBtn &&  <button id="ot-sdk-btn" className="ot-sdk-show-settings cookie-center-mobile-cls">Cookie Center</button>
            }
         
        </div>
    )
}

export default MobileMenuSidebar