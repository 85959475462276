import axios from "axios";

let headerInfo = {
    'User-Agent': 'epimotivitylabsagent'
};
const post = (url, body, paramsData = "") => {

    return axios.post(url, body, headerInfo).then((response) => {
        return response.data;
    }).catch((e) => {
        let statusCode = e.response.status;
        switch (statusCode) {
            case 404:
                return {
                    status: e.response.status,
                    statusCode: e.response.status,
                    data: "Something went wrong. Please try again.",
                    message: "Something went wrong. Please try again."
                }
                break;
            case 500:
                return {
                    status: e.response.status,
                    statusCode: e.response.status,
                    data: "An internal server error occurred. Please try again.",
                    message: "An internal server error occurred. Please try again."
                }
                break;
            default:
                return {
                    status: e.response.data.status,
                    statusCode: e.response.status,
                    data: e.response.data.data,
                    message: e.response.errorMessage
                }
        }
    });
};

const get = (urlName, paramsData) => {
    return axios
        .get(urlName)
        .then((response) => {
            return {
                res_status: "success",
                data: response?.data?.data,
                status: response?.data?.status,
                message: response?.data?.message,
            };
        })
        .catch((error) => {
            let statusCode = error.response.status;
            switch (statusCode) {
                case 404:
                    return { res_status: "error", data: "Something went wrong. Please try again.", statuscode: statusCode };
                    break;
                case 500:
                    return { res_status: "error", data: "An internal server error occurred. Please try again.", statuscode: statusCode };
                    break;
                default:
                    return { res_status: "error", data: error?.response?.data, statuscode: statusCode };
            }
        });
};

const put = (url, body) => {
    return axios.put(url, headerInfo).then((response) => {
        return response.data;
    }).catch((error) => {
        return { status: "error", statuscode: error.response?.data?.status };
    });
};

const del = (url) => {
    return axios.delete(url, headerInfo)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return { status: "error", statuscode: error.response?.data?.status };
        });
};

const Api = { post, get, put, del };
export default Api;