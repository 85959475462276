import { lazy } from 'react';
import UserLayout from '../layout/UserLayOut';

const RoutersList = [
    {
        RouteLayout: <UserLayout />,
        ProtectRoute: false,
        RouteList: [
            {
                path: '/',
                exact: true,
                roles: ["User", "Creator", "Approver"],
                accessLevel: '',
                element: lazy(() => import('../components/ai-shopping/AiShoppingResult'))
            },
            {
                path: '/ai-itinerary',
                exact: true,
                roles: ["User", "Creator", "Approver"],
                accessLevel: '',
                element: lazy(() => import('../components/ai-itinerary')),
            },
            {
                path: '/ai-itinerary/details',
                exact: true,
                roles: ["User", "Creator", "Approver"],
                accessLevel: '',
                element: lazy(() => import('../components/ai-itinerary/aiItineraryDetails')),
            },
            {
                path: '/ai-itinerary/share/:name',
                roles: ["User", "Creator", "Approver"],
                accessLevel: '',
                element: lazy(() => import('../components/ai-itinerary/aiItineraryDetailsShare')),
            },
            {
                path: '/ai-shopping',
                exact: true,
                roles: ["User", "Creator", "Approver"],
                accessLevel: '',
                element: lazy(() => import('../components/ai-shopping/AiShoppingResult'))
            }
        ]
    },
    {
        RouteLayout: <UserLayout />,
        ProtectRoute: false,
        RouteList: []
    }
];

export { RoutersList };
