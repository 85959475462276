import axios from 'axios'
import { getAuthorizationHeader } from './auth-service';
import getApi from '../../common/helpers/api/urls'

// Add a request interceptor
axios.interceptors.request.use((config) => {
    const authorizationHeader = getAuthorizationHeader();
    config['headers'] = {
        ...config.headers,
        ...authorizationHeader,
        // 'User-Agent': 'epimotivitylabsagent',
    };
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return Promise.reject(error);
});



const defaultAxiosBaseParams = {
    baseUrl: getApi.getApiUrlInfo()
}

export const axiosBaseQuery = ({ baseUrl } = defaultAxiosBaseParams) =>
    async ({ url, method, data, params, error = false, errorMessage = '' }) => {
        try {
            if (error) {
                return { data: { data: null, status: 400, success: false, message: errorMessage || 'Something Required!' } }
            }

            const result = await axios({ url: baseUrl + url, method, data, params })
            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }
