import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RoutersList } from './RoutersList';
import DycRouteSettingConfig from './DycRouteSettingConfig';
import Loader from '../common/loader/Loader';

const ResolveRoutes = () => {
    if (RoutersList) {
        return RoutersList.map((routes, i) => {
            return <DycRouteSettingConfig key={i}
                routeLayout={routes.RouteLayout}
                protectRoute={routes.ProtectRoute}
                routeList={routes.RouteList}
                index={i}
            />
        });
    }
}

const AppRoute = (props) => {
    return (
        <React.Fragment>
            <Loader isOpen={false} />
            <Suspense fallback={<h1><Loader isOpen={true} /></h1>}>
                {/* "homepage": "/ai",  //its in package.json */}
                <BrowserRouter>
                    {ResolveRoutes()}
                </BrowserRouter>
            </Suspense>
        </React.Fragment>
    )
}
export default AppRoute;