import React, { useEffect } from 'react';
import { BrowserRouter as AppRouter, Routes, Route, useLocation } from 'react-router-dom';
import Protected from './protect/Protected';

const DycRouteSettingConfig = (props) => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.analytics != undefined) {
            window.analytics.page();
        }
        //Meta router
    }, [location]);


    const { routeLayout, protectRoute, routeList, index } = props;
    if (protectRoute === false && routeLayout !== "") {
        return (<Routes>
            <Route element={routeLayout}>
                {routeList.map((route, i) => {
                    if (route.path === '/') {
                        return <Route exact path={route.path} key={index + '-' + i} element={<route.element />} />
                    }
                    return <Route key={index + '-' + i} element={<Protected userRoles={route.roles} />}>
                        <Route exact path={route.path} element={<route.element />} />
                    </Route>
                })}
            </Route>
        </Routes>);
    } else {
        return (<Routes>
            {routeList.map((route, i) => {
                return <Route key={index + '-' + i} exact path={route.path} element={<route.element />} />
            })}
        </Routes>);
    }
}
export default DycRouteSettingConfig;