import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isDialogStatus: false,
    isDisabledInput: false
}
const dialogActionSlice = createSlice({
    name: 'dialogActionInfo',
    initialState: initialState,
    reducers: {
        setDialogActionInfo: function (state, action) {
            state.isDialogStatus = action.payload;
        },
        setInputCtrlStatus: function (state, action) {
            state.isDisabledInput = action.payload;
        },
    },
});
export const { setDialogActionInfo, setInputCtrlStatus } = dialogActionSlice.actions;
export default dialogActionSlice.reducer;