import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { itineraryApiSlice } from "./itinerary-api-slices";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(itineraryApiSlice.middleware),
});

export default store;

export function setupStore(preloadedState = {}) {
  return configureStore({ 
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(itineraryApiSlice.middleware),  
    preloadedState,
  })
}

