import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './custom-fetch-base-query';
import { setChatItineraryData, setExpandActivityAccordian, setExploreItineraryData, setItineraryData, setLoadingStatus, setSelectedPlanDataForNewTrip } from './itinerary-slice';

export const itineraryApiSlice = createApi({
   reducerPath: 'itineraryApis',
   baseQuery: axiosBaseQuery(),
   tagTypes: ['ItineraryDataMasters', 'ChatItineraryData', 'ExploreItinerary'],

   endpoints: (builder) => ({

      getItineraryDataMasterData: builder.query({
         query: () => ({ url: 'ItineraryDataMaster', method: 'get' }),
         providesTags: ['ItineraryDataMasters'],
         transformResponse: (response) => response.data,
      }),

      // chat/Itinerary
      getChatItineraryData: builder.mutation({
         query: (payload) => ({ url: 'chat/Itinerary', method: 'post', data: payload }),
         invalidatesTags: ['ChatItineraryData'],
         transformResponse: (response) => response.data,
         async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
            try {
               // dispatch(setExpandActivityAccordian());
               const chatItineraryResp = await queryFulfilled;

               // if (chatItineraryResp?.status === 500) {
               //    dispatch(setItineraryData({
               //       itineraryData: {
               //          showMessage: true,
               //          assistant: chatItineraryResp?.errorMessage || 'Something went wrong, please try again later.'
               //       }
               //    }));
               //    return;
               // }

               const { data: { dayItineraryItems, ...restOfData } } = chatItineraryResp || {};

               if (dayItineraryItems?.length === 0) {
                  dispatch(setItineraryData({ itineraryData: { ...restOfData, showMessage: true } }));
                  return
               }

               dispatch(setChatItineraryData({ chatItineraryData: [] }));
               dispatch(setItineraryData({ itineraryData: {} }));

               setTimeout(() => {
                  dispatch(setSelectedPlanDataForNewTrip({ filedName: 'searchTerm', value: '' }))
                  dispatch(setChatItineraryData({ chatItineraryData: dayItineraryItems }));
                  dispatch(setItineraryData({ itineraryData: { ...restOfData, showMessage: true } }));
               }, 100);

            } catch (err) {
               dispatch(setLoadingStatus({ loading: false }))
               dispatch(setChatItineraryData({ chatItineraryData: [], itineraryData: {} }));
            }
         },
      }),

      // chat/UpdateDisplayOrder
      updateActivityDisplayOrder: builder.mutation({
         queryFn: async (data, queryApi, extraOptions, baseQuery) => {
            const state = queryApi.getState();
            const payload = {
               ...state.itineraryGeneralInfo.updateActivityDisplayOrderPayload
            };
            delete payload?.day;
            return await baseQuery({ url: 'chat/UpdateDisplayOrder', method: 'post', data: payload })
         },
         // query: (payload) => ({ url: 'chat/UpdateDisplayOrder', method: 'post', data: payload }),
         invalidatesTags: ['ChatItineraryData'],
         // transformResponse: (response) => response.data,
      }),

      // chat/GetExploreItinerary
      getExploreItinerary: builder.mutation({
         query: (payload) => ({ url: `chat/GetExploreItinerary`, method: 'post', data: payload }),
         invalidatesTags: ['ExploreItinerary'],
         transformResponse: (response) => response.data,
         async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
            try {
               const { data: exploreItineraryData } = await queryFulfilled;
               dispatch(setExploreItineraryData({
                  packages: exploreItineraryData?.packages || [],
                  activities: exploreItineraryData?.activities || [],
                  attractions: exploreItineraryData?.attractions || [],
                  restaurants: exploreItineraryData?.restaurants || []
               }));

            } catch (err) {
               dispatch(setExploreItineraryData({ packages: [], activities: [], attractions: [], restaurants: [] }));
            }
         },
      }),


      ///chat/ManageItinerary
      manageItinerary: builder.mutation({
         queryFn: async (data, queryApi, extraOptions, baseQuery) => {
            const state = queryApi.getState();
            const payload = state.itineraryGeneralInfo.manageItineraryPayload;
            return await baseQuery({ url: 'chat/ManageItinerary', method: 'post', data: payload })
         },
         invalidatesTags: [{ type: 'ChatItineraryData', id: 'LIST' }],

      }),


      //chat/DeleteItinerary
      deleteItinerary: builder.mutation({
         query: (payload) => ({ url: 'chat/DeleteItinerary', method: 'post', data: payload }),
         invalidatesTags: ['ChatItineraryData'],
      }),

      //chat/UpdateActivityPercentage
      updateActivityPercentage: builder.mutation({
         queryFn: async (data, queryApi, extraOptions, baseQuery) => {
            const state = queryApi.getState();
            const dayItinerary = state.itineraryGeneralInfo.dayItinerary;
            const payload = {
               sessionId: state.itineraryGeneralInfo.sessionId,
               dayNumber: dayItinerary.day?.dayNumber,
               activityPercentage: dayItinerary.activityPercentage
            }
            return await baseQuery({ url: 'chat/UpdateActivityPercentage', method: 'post', data: payload })
         },
         invalidatesTags: [{ type: 'ChatItineraryData', id: 'LIST' }],
      }),

   }),
});


export const {
   useGetItineraryDataMasterDataQuery,
   useGetChatItineraryDataMutation,
   useUpdateActivityDisplayOrderMutation,
   useGetExploreItineraryMutation,
   useManageItineraryMutation,
   useDeleteItineraryMutation,
   useUpdateActivityPercentageMutation
} = itineraryApiSlice;