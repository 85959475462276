import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../common/helpers/api";
import getApiUrl from "../../common/helpers/api/urls";
import { Icon } from "@iconify/react";

export const getNaviagtionInfo = createAsyncThunk(
  'naviagtionInfo/navData',
  async () => {
    const url = getApiUrl.getApiUrlInfo("nav_url");
    return await Api.get(url, {});
  }
)

const initialState = {
  loading: false,
  resStatus: false,
  resStatusCode: 200,
  navigationData: [
    {
      id: 0,
      title: 'Destinations',
      icon: <Icon icon="material-symbols-light:location-on" />,
      sideDrawerPosition: 'left',
      drawerDetails: {
        title: 'DESTINATIONS',
        footer: 'View all destinations'
      }
    },
    {
      id: 1,
      title: 'Deals',
      icon: <Icon icon="ic:sharp-local-offer" />,
      sideDrawerPosition: 'left',
      drawerDetails: {
        title: 'TRAVEL DEALS',
        footer: 'View all deals'
      }

    },
    {
      id: 2,
      title: 'Resorts',
      // icon: <Icon icon="material-symbols-light:apartment" />
      icon: <Icon icon="ic:baseline-apartment" />,
      sideDrawerPosition: 'left',
      drawerDetails: {
        title: 'FEATURED RESORT BRANDS',
        footer: 'View All resort brands'
      }

    },
    {
      id: 3,
      title: 'Get Help',
      icon: <Icon icon="f7:question-circle-fill" />,
      sideDrawerPosition: 'right',
      drawerDetails: {
        title: 'GET HELP',
        footer: null
      }

    },
    {
      id: 4,
      title: 'Find My Trip',
      icon: <Icon icon="mdi:briefcase" />,
      sideDrawerPosition: false,
      drawerDetails: null
    }
  ],
  message: null,
  navigationSelected: null,
  isMobileMenuSidebarActive: false,
}

const navigationDataSlice = createSlice({
  name: 'navigationData',
  initialState: initialState,
  reducers: {
    navigationDataInfo: function (state, action) {
      state.navigationData = action.payload;
    },
    setSelectednavigationData: function (state, action) {
      state.navigationSelected = action.payload;
    },
    setHeaderMobileMenuSidebarActiveStatus: function (state, action) {
      state.isMobileMenuSidebarActive = action.payload.isActive;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNaviagtionInfo.pending, (state, action) => {
      state.loading = true;
    }).addCase(getNaviagtionInfo.fulfilled, (state, action) => {
      // console.log("action.payload.data");
      // console.log(action.payload.data);
      state.loading = false;
      if (action.payload.status) {
        state.resStatus = action.payload.data.status;
        state.resStatusCode = action.payload.status;
        if (action.payload.data.length > 0) {
          const d = []
          const icons = ['material-symbols-light:location-on', 'ic:sharp-local-offer', 'ic:baseline-apartment', 'f7:question-circle-fill', 'mdi:briefcase'];
          action.payload.data.forEach((element, index) => {
            d.push({
              ...element,
              id: index,
              icon: icons[index]
            })
          });
          console.log("d", d);
          state.navigationData = d;
        }
      } else {
        state.resStatusCode = action.payload.statusCode;
        state.navigationData = action.payload.data;
        state.message = action.payload.errorMessage;
      }
    }).addCase(getNaviagtionInfo.rejected, (state, action) => {
      state.loading = false;
      state.message = `some thing went wrong!`;
    });
  }
});
export const { navigationDataInfo, setSelectednavigationData, setHeaderMobileMenuSidebarActiveStatus } = navigationDataSlice.actions;
export default navigationDataSlice.reducer;