import React from "react";
import BotIcon from "../../assets/img/ai-assistant.png";
import { useSelector } from "react-redux";

const BouncingDotsLoader = (props) => {
  const selector = useSelector((state) => {
    return state;
  });

  const aiAsstMessage = selector.aiShoppingInfo.aiAsstMessage;
  return (
    <>
      <div className={`bouncing-loader container ${(aiAsstMessage && aiAsstMessage?.length > 0) ? 'bouncing-loader-conditional-cls' : ''}`} >
        {(aiAsstMessage && aiAsstMessage?.length > 0) ? <img src={BotIcon} alt="BotIcon" width={'40px'} height={'40px'} className="bouncing-loader-bot-img" /> : null}
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default BouncingDotsLoader;