const api_urls = {
    "session_url": "chat/session",
    "chat_prompt_url": "chat/prompt",
    "chat_inquiries_url": "chat/inquiries",
    "chat_questionary_url": "data",
    "popular_searches": "data/topqueries",
    "nav_url": "GetCCHeader"
};

const apiEndpoints = {
    'ai.cheapcaribbean.com': 'https://aiapi.cheapcaribbean.com/api/',
    'localhost:3000': 'https://aiapi.dev.cheapcaribbean.com/api/',
    'ai.dev.cheapcaribbean.com': 'https://aiapi.dev.cheapcaribbean.com/api/',
    'ai.alpha.cheapcaribbean.com': 'https://aiapi.alpha.cheapcaribbean.com/api/',
    'ai.beta.cheapcaribbean.com': 'https://aiapi.beta.cheapcaribbean.com/api/',
    'prodcentralusaiuias-prodcentralusaiuias-slot01.azurewebsites.net': 'https://prodcentralusaiapias-prodcentralusaiapias-slot01.azurewebsites.net/api/',
    'betacentralusaiuias-betacentralusaiuias-slot01.azurewebsites.net': 'https://betacentralusaiapias-betacentralusaiapias-slot01.azurewebsites.net/api/',
    'alphacentralusaiuias-alphacentralusaiuias-slot01.azurewebsites.net': 'https://alphacentralusaiapias-alphacentralusaiapias-slot01.azurewebsites.net/api/',
    'devcentralusaiuias-devcentralusaiuias-slot01.azurewebsites.net': 'https://devcentralusaiapias-devcentralusaiapias-slot01.azurewebsites.net/api/'
};

function getApiUrlInfo(apiName = "", location = window.location.href.split('/')[2]) {
    let apiUrl = apiEndpoints[location] || `${process.env.REACT_APP_API_END_POINT}`;

    if (apiName) {
        apiUrl += api_urls[apiName];
    }

    return apiUrl;
}

const getApiUrl = { getApiUrlInfo };
export default getApiUrl;