import sessionInfoSlice from './slices/sessionInfoSlice';
import dialogActionSlice from './slices/dialogActionSlice';
import originDataSlice from './slices/originDataSlice';
import aiShoppingInfoSlice from './slices/shoppingAssistanceSlice';
import navigationDataSlice from './slices/navigationSlice';

//itinerary generator
import { itineraryApiSlice } from './itinerary-api-slices';
import itineraryGeneratorSlice from './itinerary-api-slices/itinerary-slice';

export const apiSliceMiddlewares = [
  itineraryApiSlice.middleware
];

const rootReducer = {
    sessionInfo: sessionInfoSlice,
    originData: originDataSlice,
    dialogActionInfo: dialogActionSlice,
    navigationDataSlice:navigationDataSlice,
    aiShoppingInfo: aiShoppingInfoSlice,
    itineraryGeneralInfo: itineraryGeneratorSlice,
    [itineraryApiSlice.reducerPath]: itineraryApiSlice.reducer,
}

export default rootReducer;

// export function setupStore(preloadedState) {
//     return configureStore({ 
//       reducer: rootReducer,
//       middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware().concat(apiSliceMiddlewares),
//       preloadedState,
//     })
//   }




